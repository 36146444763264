import React, { useRef, useState } from "react"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import rgbHex from "rgb-hex"

import colors from "../../theme/sections/colors"
import { ReactComponent as SvgPlay } from "../../images/icons/play.svg"

const Container = styled.div`
  height: 0;
  padding-bottom: 56%;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const PlayButton = styled.button`
  opacity: ${(props) => (!props.isPlaying && props.isReady ? 1 : 0)};
  width: ${rem(90)};
  height: ${rem(90)};
  background-color: ${(props) => props.theme.colorEnglishViolet};
  z-index: 11;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  padding: ${rem(30)};

  @media ${(props) => props.theme.mediumDown} {
    width: ${rem(60)};
    height: ${rem(60)};
    padding: ${rem(18)};
  }

  svg {
    width: 100%;
    height: 100%;
    position: relative;
    left: ${rem(5)};

    @media ${(props) => props.theme.mediumDown} {
      left: ${rem(2)};
    }
  }
`

const Video = ({
  data: {
    acf: {
      url,
      // personName,
      // personTitle,
      // customer,
    },
  },
  withControls = false,
  onEndedHandler,
  onReadyHandler,
  index,
}) => {
  if (!url) return null

  const [isPlaying, setIsPlaying] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const player = useRef(null)

  const onReadyLocalHandler = () => {
    setIsReady(true)
    if (onReadyHandler) {
      onReadyHandler(index, setIsPlaying)
    }
  }

  return (
    <Container>
      <PlayButton
        onClick={() => setIsPlaying(!isPlaying)}
        isReady={isReady}
        isPlaying={isPlaying}
      >
        <SvgPlay />
      </PlayButton>
      <ReactPlayer
        ref={player}
        url={url}
        width="100%"
        height="100%"
        playing={isPlaying}
        controls={withControls}
        onEnded={onEndedHandler}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        onReady={onReadyLocalHandler}
        config={{
          vimeo: {
            playerOptions: {
              color: rgbHex(colors.colorRed),
              controls: true,
            },
          },
        }}
      />
    </Container>
  )
}

Video.propTypes = {
  data: PropTypes.object.isRequired,
  withControls: PropTypes.bool,
  onEndedHandler: PropTypes.func,
  onReadyHandler: PropTypes.func,
  index: PropTypes.number,
}

export default Video
