import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.article``

const Content = styled.blockquote.attrs({ className: `styled-h4` })`
  &::before {
    content: "“";
  }

  &::after {
    content: "”";
  }
`

const Author = styled.p.attrs({ className: `styled-p -info` })`
  margin-top: 1.5em;
`

const Quote = ({ data: { text, firstname, lastname, title, company } }) => {
  const author = [
    [firstname, lastname].filter(Boolean).join(` `),
    title,
    company,
  ]
    .filter(Boolean)
    .join(`, `)

  return (
    <Container>
      <Content dangerouslySetInnerHTML={{ __html: text }} />

      {author && <Author dangerouslySetInnerHTML={{ __html: author }} />}
    </Container>
  )
}

Quote.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Quote
