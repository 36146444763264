import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import SwiperCore from "swiper"
import Pagination from "swiper/modules/pagination/pagination"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"
import { rem } from "polished"
import "swiper/swiper.scss"
import "swiper/modules/pagination/pagination.scss"

import Grid from "../dotgrid/grid"
import Video from "../video"
import { ReactComponent as SvgArrow } from "../../images/icons/arrow.svg"

SwiperCore.use([Pagination])

const Carousel = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${rem(1600)};

  > div {
    margin: ${rem(78)} ${rem(82)};
    padding-bottom: ${rem(40)};

    @media ${(props) => props.theme.smallDown} {
      margin: ${rem(70)} 0 ${rem(40)} 0;
    }
  }

  .swiper-slide {
    position: relative;

    // Cover 90% of the height and width of the video to block the click and drag events passing through and allow swiping
    // while keeping controls and share buttons usable
    @media ${(props) => props.theme.smallDown} {
      &::after {
        content: "";
        height: 80%;
        width: 90%;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }

  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 ${rem(12)};
    background: ${(props) => props.theme.colorGreyDarker};
    opacity: 1;
    transform: scale(0.9);
  }

  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: ${(props) => props.theme.colorRed};
    transform: scale(1.7);
  }
`

const SwiperControls = styled.section`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 99;
  padding: 0 ${rem(10)};

  @media ${(props) => props.theme.smallDown} {
    display: none;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .swiper-button-prev {
    svg {
      transform: scaleX(-1);
    }
  }

  svg {
    height: ${rem(22)};
    width: ${rem(11)};
  }
`

const VideoCarousel = ({ data }) => {
  const [swiperInstance, setSwiperInstance] = useState(Function.prototype)
  const [playHandlers, setPlayHandlers] = useState({})
  const [prevDisabled, setPrevDisabled] = useState(false)
  const [nextDisabled, setNextDisabled] = useState(false)

  // Probably can be refactored into using some of the provided events by swiper
  const checkIndex = () => {
    if (swiperInstance && swiperInstance.activeIndex === 0) {
      setPrevDisabled(true)
    } else if (swiperInstance && swiperInstance.activeIndex !== 0) {
      setPrevDisabled(false)
    }

    if (
      swiperInstance &&
      swiperInstance.slides &&
      swiperInstance.activeIndex === swiperInstance.slides.length - 1
    ) {
      setNextDisabled(true)
    } else if (
      swiperInstance &&
      swiperInstance.slides &&
      swiperInstance.activeIndex < swiperInstance.slides.length - 1
    ) {
      setNextDisabled(false)
    }
  }

  const goToNextVideoAndPlay = () => {
    if (!nextDisabled) {
      swiperInstance.slideNext()
      playHandlers[swiperInstance.activeIndex](true)
    }
  }

  const onSlideChangeHandler = (swiper) => {
    playHandlers[swiperInstance.previousIndex](false)
    // Checks if prev or next buttons should be disabled / enabled
    checkIndex()
  }

  const getPlayHandlers = (index, playHandler) => {
    setPlayHandlers({
      ...playHandlers,
      [index]: playHandler,
    })
  }

  const prevButtonHandler = () => {
    swiperInstance.slidePrev()
  }

  const nextButtonHandler = () => {
    swiperInstance.slideNext()
  }

  if (!data.acf.url && !data.acf.carouselVideos) return null

  if (data.acf.type === `carousel`) {
    return (
      <Carousel>
        <Swiper
          spaceBetween={50}
          pagination={true}
          slidesPerView={1}
          navigation={{
            prevEl: `.swiper-button-prev`,
            nextEl: `.swiper-button-next`,
          }}
          onSlideChange={onSlideChangeHandler}
          onAfterInit={(swiper) => {
            setSwiperInstance(swiper)
            checkIndex()
          }}
        >
          {data.acf.carouselVideos.map((video, index) => {
            return (
              <SwiperSlide className="swiper-slide" key={index}>
                <Video
                  data={{ acf: { url: video.url } }}
                  withControls
                  onEndedHandler={goToNextVideoAndPlay}
                  onReadyHandler={getPlayHandlers}
                  index={index}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <SwiperControls className="swiper-controls">
          <button
            onClick={prevButtonHandler}
            className="swiper-button-prev"
            disabled={prevDisabled}
          >
            <SvgArrow />
          </button>
          <button
            onClick={nextButtonHandler}
            className="swiper-button-next"
            disabled={nextDisabled}
          >
            <SvgArrow />
          </button>
        </SwiperControls>
      </Carousel>
    )
  } else {
    return (
      <Grid>
        <Video data={{ acf: { url: data.acf.url } }} withControls />
      </Grid>
    )
  }
}

VideoCarousel.propTypes = {
  data: PropTypes.object,
}

export default VideoCarousel
